@use '@angular/material' as mat;

@include mat.core();

@import 'remixicon/fonts/remixicon.css';

:root {
  --dk-gray: #202828;
  --dk-gray-2: #53565f;
  --med-gray: #505858;
  --med-gray-2: #dfe0e1;
  --soft-gray: #f5f5f5;
  --light-gray: #f5f5f5;
  --lighter-gray: #fcfcfc;
  --pearl: #cfd2cd;
  --light-pearl: #ededed;
  --light-teal: #ecf0ef;
  --tan: #f9f8f4;
  --offwhite: #f4f4f4;
  --white: #fff;
  --active: #0d8282;
  --info: #007bbd;
  --info-light: #e5f3fb;
  --success: #3cb959;
  --warn: #e6b00b;
  --warn-text: #846815;
  --error: #b32631;
  --error-light: #f8dde0;
  --Mackinac: P22Mackinac-Medium_6, serif;
  --Maax: Maax, Roboto, Franklin Gothic Medium, Tahoma, sans-serif;
  --Maax-Medium: Maax-Medium, sans-serif;
  --content-width: min(100% - 24px, 1200px);
}

@font-face {
  font-family: Maax;
  font-display: swap;
  src: url('/assets/fonts/Maax.woff') format('woff');
}

@font-face {
  font-family: Maax-Medium;
  font-display: block;
  src: url('/assets/fonts/Maax-Medium.woff') format('woff');
}

@font-face {
  font-family: Maax-Bold;
  font-display: swap;
  src: url('/assets/fonts/Maax-Bold.woff') format('woff');
}

@font-face {
  font-family: P22Mackinac-BookItalic_5;
  font-display: swap;
  src: url('/assets/fonts/P22Mackinac-BookItalic_5.woff') format('woff');
}

@font-face {
  font-family: P22Mackinac-Book_13;
  font-display: swap;
  src: url('/assets/fonts/P22Mackinac-Book_13.woff') format('woff');
}

@font-face {
  font-family: P22Mackinac-MedItalic_22;
  font-display: swap;
  src: url('/assets/fonts/P22Mackinac-MedItalic_22.woff') format('woff');
}

@font-face {
  font-family: P22Mackinac-Medium_6;
  font-display: block;
  src: url('/assets/fonts/P22Mackinac-Medium_6.woff') format('woff');
}

// major element style
a {
  text-decoration: none;
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.spacer {
  flex: 1 1 auto;
}

.hidden {
  display: none !important;
}

.full-width {
  width: 100%;
}

// general use flex styling
.flex-row,
.mat-list-base.flex-row {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.flex-row-wrap,
.mat-list-base.flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.flex-row-inline,
.mat-list-base.flex-row-inline {
  display: inline-flex;
  flex-direction: row;
  box-sizing: border-box;
}

.flex-col,
.mat-list-base.flex-col {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.flex-col-inline,
.mat-list-base.flex-col-inline {
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
}

.flex-row-col-responsive {
  display: flex;
  box-sizing: border-box;
}

.flex-row-wrap-col-responsive {
  display: flex;
  box-sizing: border-box;
}

@media screen and (max-width: 599px) {
  .flex-row-col-responsive,
  .flex-row-wrap-col-responsive {
    flex-direction: column;
  }
}

@media screen and (min-width: 600px) {
  .flex-row-col-responsive {
    flex-direction: row;
  }
  .flex-row-wrap-col-responsive {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.flex-center-center {
  place-content: center center;
  align-items: center;
}

.flex-start-space-between {
  place-content: flex-start space-between;
  align-items: flex-start;
}

.flex-center-space-between {
  place-content: center space-between;
  align-items: center;
}

.flex-stretch-space-between {
  place-content: stretch space-between;
  align-items: stretch;
}

.flex-center-space-around {
  place-content: center space-around;
  align-items: center;
}

.flex-center-start {
  place-content: center flex-start;
  align-items: center;
}

.flex-start-center {
  place-content: flex-start center;
  align-items: flex-start;
}

.flex-start-start {
  place-content: flex-start flex-start;
  align-items: flex-start;
}

.flex-center-end {
  place-content: center flex-end;
  align-items: center;
}

.flex-end-start {
  justify-content: flex-start;
  align-items: flex-end;
}

.flex-start-end {
  justify-content: flex-end;
  align-items: flex-start;
}

.flex-end-space-between {
  place-content: flex-end space-between;
  align-items: flex-end;
}

.center {
  display: flex;
  justify-content: center;
}

.center-center {
  display: flex;
  place-content: center center;
  align-items: center;
}

.flex-gap-4 {
  gap: 4px;
}

.flex-gap-6 {
  gap: 6px;
}

.flex-gap-12 {
  gap: 12px;
}

.flex-gap-16 {
  gap: 16px;
}

.flex-gap-18 {
  gap: 18px;
}

.flex-gap-20 {
  gap: 20px;
}

.flex-gap-30 {
  gap: 20px;
}

.flex-1 {
  flex: 1; // Same as flex: 1 1 0 (grow, shrink, basis 0)
}

.flex-2 {
  flex: 2;
}

.flex-grow {
  flex-grow: 1;
}

.hide-xs {
  @media screen and (max-width: 599px) {
    display: none;
  }
}

.hide-gt-xs,
.hide-gt-xs.mat-icon,
.hide-gt-xs.mat-icon-button,
.hide-gt-xs.mat-expansion-panel-header,
.mat-list-base .hide-gt-xs.mat-list-item {
  @media screen and (min-width: 600px) {
    display: none;
  }
}

.hide-sm {
  @media screen and (max-width: 959px) {
    display: none;
  }
}

.hide-gt-sm {
  @media screen and (min-width: 960px) {
    display: none;
  }
}

// Nav bar styling
.horizontal-nav .mat-list-base .mat-list-item .mat-list-item-content {
  padding: 0 min(30px, 2vw);
}

.mat-tab-header {
  height: 40px;
}

.generic-profile-avatar {
  background-image: url('./assets/generic-profile-avatar.png');
  background-size: cover;
}

// Search box styling
.search-box {
  @include mat.elevation(1);
  min-height: 64px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  font-size: 20px;
}

.search-box mat-icon {
  padding-bottom: 10px;
}

// Loading indicator styling
.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Flex alignment
.flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

// Mat-Stepper styling (hiding it for mobile)
@media screen and (max-width: 959px) {
  .mat-horizontal-stepper-header-container {
    display: none !important;
  }
}

// Dialog styling
.dialog-no-pad mat-dialog-container {
  padding: 0;
}

.dialog-warn-box {
  margin: 0 0 14px 0;
  width: 100%;
  padding: 5px;
}

.hold-dialog {
  max-width: 320px;
}

.dialog-body {
  margin-bottom: 14px;
}

.dialog-button-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 40px 0 0 0;
}

.dialog-button-row > button {
  margin-right: 8px;
  flex: 1 1 45%;
}

.dialog-button-row > button:last-child,
a:last-child {
  margin-right: inherit;
}

// Save Footer styling
.save-footer {
  position: fixed;
  height: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 1em;
  z-index: 9;
}

.save-footer-content {
  height: 100%;
  max-width: var(--content-width);
  margin: 0 auto;
}

.save-button-group {
  width: 156px;
  height: 50px;
  text-align: center;
  margin: 10px;
  line-height: 30px;
}

// Button styling
.button-row-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.button-row-right > button {
  margin-right: 8px;
}

.button-row-right > button:last-child,
a:last-child {
  margin-right: inherit;
}

.button-row-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.button-row-left > button {
  margin-right: 8px;
}

.button-row-left > button:last-child,
a:last-child {
  margin-right: inherit;
}

.button-error-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.button-icon-right mat-icon {
  padding-left: 15px;
}

.button-icon-left mat-icon {
  padding-right: 15px;
}

// Dropdown styling
.remove-max-width {
  max-width: none !important;
}

.remove-padding .mat-menu-content:not(:empty) {
  padding: 0 !important;
}

// Pill styling
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary[selected],
.mat-chip.mat-standard-chip.mat-primary {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.small-pill.mat-chip.mat-standard-chip {
  padding-top: 0.7em;
  font-weight: bold;
  font-size: 12px;
  min-height: 25px;
}

// Table styling
table {
  width: 100%;
}

tr.mat-row.mx-taller-row {
  height: 85px;
}

.highlight {
  cursor: pointer;
}

// Input field styling
mat-form-field.mat-form-field {
  margin-bottom: 7px;
  width: 100%;
}

.mat-form-field.app-input-no-underline .mat-input-underline {
  display: none;
}

button.mat-button,
button.mat-stroked-button,
button.mat-raised-button {
  border-radius: 0;
}

// Barcode styling
ngx-barcode > .barcode > svg {
  width: 278px;
  height: 60px;
}

.empty-list {
  box-sizing: border-box;
  width: 100%;
  height: 82px;
  margin-top: 24px;
  padding: 30px;
  background-color: var(--light-gray);
}

td.table-row-title {
  color: var(--active);
}

.disabled-icon {
  color: var(--med-gray-2);
}

.page-body-wrapper {
  max-width: var(--content-width);
  margin: auto;
  padding: 32px 0;
}

.clear-for-footer {
  height: 100px;
}

// Items Out + Holds - desktop
table th.cdk-column-checkbox.mat-column-checkbox,
table td.cdk-column-checkbox.mat-column-checkbox,
table th.cdk-column-actions.mat-column-actions,
table td.cdk-column-actions.mat-column-actions {
  padding-left: 0;
  padding-right: 0;
}

.cdk-column-title.mat-column-title,
.cdk-column-author.mat-column-author,
.cdk-column-mediatype.mat-column-mediatype,
.cdk-column-location.mat-column-location,
.cdk-column-dueDate.mat-column-dueDate,
.cdk-column-status.mat-column-status,
.cdk-column-actions.mat-column-actions {
  padding: 0 6px;
}

// Items Out + Holds - desktop
@media screen and (max-width: 599px) {

  .mat-header-row {
    display: none;
  }

  .mobile-title-link {
    font-family: var(--Maax-Medium);
    font-weight: 500;
  }

  td.mat-cell.cdk-column-checkbox,
  td.mat-cell.cdk-column-mobile,
  td.mat-cell.cdk-column-actions {
    padding: 12px 8px;
    vertical-align: top;
  }

  .page-body-wrapper {
    padding-top: 16px;
  }

  div.page-body-wrapper div.mat-display-1 {
    margin-bottom: 16px;
  }

  .cdk-column-actions {
    width: 10%;

    div > :first-child {
      margin-bottom: 8px;
    }
  }

  div.mobile-table-entry > span,
  div.mobile-table-entry > rn-hold-status {
    margin: 2px 0;
  }
  div.mobile-table-entry > a {
    margin-bottom: 2px;
  }

  div.save-footer {
    height: min(80px, 10vh);
  }
}

div.mat-tab-labels {
  justify-content: space-evenly;
}
